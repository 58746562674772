import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

const headingHashMap = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6'
}

const Heading = ({ type, className, children, dataAutomation, isLoading, textColor, ...rest }) => {
  const Type = headingHashMap[type] ? headingHashMap[type] : 'h1';
  return (
    isLoading
      ? <Skeleton width={180} height={20} className='flex-shrink-0' />
      : <Type className={[textColor || 'text-gray-900', rest?.weight || 'font-semibold', className].join(' ')} data-automation={dataAutomation} {...rest}>
        {children}
      </Type>

  )
}

export default Heading

Heading.propTypes = {
  /**
   * `H1`: font-size: 40px; line-height: 48px
   * `H2`: font-size: 32px; line-height: 40px
   * `H3`: font-size: 25px; line-height: 28px
   * `H4`: font-size: 22px; line-height: 26px
   * `H5`: font-size: 16px; line-height: 20px
   * `H6`: font-size: 12px; line-height: 20px
   */
  type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  className: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
}
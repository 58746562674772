import React from 'react'
import Text from 'atoms/Text'
import ImageNext from 'atoms/ImageNext'

const offers = 4000;

const GiftRibbon = (props) => {
  const { className, offerClass = '', headingClass = '', subHeadingClass = '', title, msg } = props
  return (
    <>
      {title && <div className={`${className} flex items-center px-4 gap-3 from-[#FE4600] to-[#FD951A] py-2 bg-gradient-to-r`}>
        <div>
          <ImageNext src='/images/gift-pic.png' width={26} height={30} />
        </div>
        <div className={`${offerClass} basis-full`}>
          <Text type='bodySmall' className={`${headingClass}`} color="text-basic-white" fontWeight={700}>{title}</Text>
          <Text type='caption' className={`${subHeadingClass}`} color="text-basic-white" fontWeight={500}>
            <div dangerouslySetInnerHTML={{ __html: msg }}></div>
          </Text>
        </div>
      </div>}
    </>
  )
}


export default GiftRibbon

import React from "react";
import Text from "../atoms/Text";

import { useRouter } from "next/router";
import Button from "atoms/Button";
import ImageNext from "atoms/ImageNext";
import { getAuth } from "services/identity.service";
import { USER_TYPES } from "constant";

const NirogPartnerBanner = ({ buttonText, className , fontSize}) => {
  const router = useRouter();
  const auth = getAuth();
  const redirectToNirogPartner = () => {
    router.push("/reseller");
  };
  if ((!auth && router.pathname != '/practitioner') || (auth && ![USER_TYPES?.STUDENTS, USER_TYPES?.DOCTORS, USER_TYPES?.DOCTOR_DISTRIBUTOR].includes(auth?.user?.userType))) {
    return <></>;
  }  

  return (
    <div
      className={`${className} rounded-2xl flex justify-between flex-row`}
      style={{
        background: "linear-gradient(to right, #7C2BBC, #B03FFF)"
      }}
    >
      <div className="flex-grow basis-[100%]">
        <Text
          className={`text-basic-white leading-tight ${fontSize ? fontSize :"text-sm md:text-lg"}`}
          fontWeight={700}
        >
          {
            "Use Nirog Partner to ship medicines to your patients, anywhere in India"
          }
        </Text>
        <Text
          fontWeight={400}
          className={`text-basic-white mt-2 md:mt-1 mb-2 leading-tight  ${fontSize ? fontSize :"text-sm md:text-lg"}`}
        >
          {"Earn margins and offers on orders in Nirog Partner!"}
        </Text>
        <Button
          onClick={redirectToNirogPartner}
          fontWeight={600}
          size={'extraSmall'}
          className={
            " text-primary1-500 bg-basic-white hover:text-basic-white text-sm leading-tight"
          }
        >
          {buttonText}
        </Button>
      </div>
      <ImageNext
        containerClass="w-25 relative"
        alt="Nirog Partner"
        className="object-contain"
        src="/images/carry-bag.webp"
        width={"100px"}
        layout="fill"
        height={"101px"}
      />
    </div>
  );
};
export default NirogPartnerBanner;

import { ControlledMenu, MenuItem, useMenuState } from '@szhsin/react-menu';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import Button from './../atoms/Button';
import Text from './../atoms/Text';

import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import IconClose from 'atoms/SvgIcons/IconClose.svg';
import IconPolygon from 'atoms/SvgIcons/IconPolygon.svg';
import GiftRibbon from 'molecules/GiftRibbon';

const EcommSearchMenu = ({ className, subClass, isShowSearch, setIsOpenSearch, title, menuButton, children, anchorRef, type, align = 'center',
    handlerMedicineRemove, onClick, pointerLeftPosition, maxWidth, dataAutomation, isDynamicWidth, cartDetails, offers, showAddMoreItemsToOrder, ...rest }) => {
   
    const { toggleMenu, ...menuProps } = useMenuState({ transition: true });
    const [styles, setStyles] = useState(null)
   
    useEffect(() => {
        if (isShowSearch) {
            toggleMenu(true);
        } else {
            toggleMenu(false);
        }
    }, [isShowSearch]);
    useEffect(() => {
        if (anchorRef) {
            setStyles({ height: anchorRef.current?.offsetHeight })
        }
    }, []);
  
    return (
        <>
            <ControlledMenu
                transition
                align={align}
                direction={'bottom'}
                captureFocus={false}
                anchorRef={anchorRef}
                menuItemFocus={true}
                menuClassName='menu--search'
                onClose={() => { setIsOpenSearch(false); }}
                className={`menu--default ${isDynamicWidth ? 'menu--dynamicWidth' : ''}`}
                {...(menuButton && { menuButton })}
                {...(isDynamicWidth && {
                    menuStyles: { marginTop: styles && (styles.height + 5) },
                })}
                {...(type === 'pointer' && {
                    menuStyles: { marginLeft: pointerLeftPosition, maxWidth: maxWidth },
                    onMouseLeave: () => setIsOpenSearch(false),
                    captureFocus: true
                })}
                {...menuProps}
                {...rest}
            >

                {title && <>
                    <div className='pl-5 min-h-[36px] md:min-h-[56px] pr-2 flex justify-between items-center bg-basic-white relative z-10 rounded-2xl'>
                        <Text className='capitalize' fontWeight={700} color='text-basic-blackPure'>{title}</Text>
                        <MenuItem className='menu__clearButton group' data-automation={`${dataAutomation}-close-button`}>
                            <IconClose className='w-6 fill-none stroke-gray-900 group-hover:stroke-primary1-500' />
                        </MenuItem>
                    </div>
                </>}
                <div className={`${className} overflow-hidden border-gray-100 border`}>
                    {type === 'pointer' && <IconPolygon className='w-6 absolute right-4 -top-2.5 z-9 iconShadow' />}
                    <div id="scrollableDiv" className={`${subClass} bg-basic-white overflow-y-auto customScrollbar relative z-10`}>
                        {children}
                    </div>
                </div>
                {showAddMoreItemsToOrder && onClick && type === 'pointer' &&
                    <GiftRibbon
                        className='absolute bottom-[78px] w-full z-100'
                        title={showAddMoreItemsToOrder.title}
                        msg={showAddMoreItemsToOrder.message}
                        offers={offers}
                    />
                }

                {onClick && type === 'pointer' &&
                    <Button disabled={cartDetails > 0 ? false : true} size='medium' className='text-base max-w-50 w-full px-2 my-3.5 mx-auto' onClick={onClick} dataAutomation={`${dataAutomation}-proceed`}>Proceed to cart</Button>
                }

            </ControlledMenu>
        </>
    )
};

EcommSearchMenu.defaultProps = {
    className: '',
    type: '',
    align: 'center',
}

EcommSearchMenu.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
}

export default EcommSearchMenu;

const debounce = (func, delay) => {
    let debounceTimer;

    return function (...rest) {
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        debounceTimer = setTimeout(() => func(...rest), delay);
    };
};

const indianCurrency = ({ rupee, isShowRupeeSymbol }) => {
    let rupeeWithSymbol = rupee?.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR'
    })

    if (!isShowRupeeSymbol) {
        rupeeWithSymbol = rupeeWithSymbol?.replace('₹', '');
    }

    return rupeeWithSymbol;
}

const Title = {
    1: "Dr.",
    2: "Mr.",
    3: "Mrs.",
    4: "Miss.",
}
/**
 * We are showing `Dr.` if userType 2 otherwise goes with title
 * @param {fullName} username
 * @param {userType} 2 for doctor
 * @param {title} 1 for Dr.| 2 for Mr. | 3 for Mrs. | 4 for Miss.
 */
const usernameSalutation = ({ fullName, userType, title }) => {
    const firstTitle = Title[title] || Title['1'];
    return firstTitle ? `${firstTitle} ${fullName}` : `${fullName}`;
}

/**
 * 
 * @param {Number} num 
 */
const currencyFormatter = (num) => {
    return Math.abs(num) > 999
        ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k'
        : Math.sign(num) * Math.abs(num)
}

const timeFormatter = ({ timeInSeconds = undefined, timeInMilliseconds = undefined, formate = 'regular' }) => {
    const d = typeof timeInSeconds !== 'undefined'
        ? timeInSeconds
        : timeInMilliseconds / 1000;


    const h = Math.floor(d / 3600);
    const m = Math.floor(d % 3600 / 60);
    const s = Math.floor(d % 3600 % 60);
    if (formate == 'regular') {
        let hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
        let mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
        let sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        return hDisplay + mDisplay + sDisplay;
    } else {
        return `${h}:${m}:${s}`
    }
}

const formatLocationText = (text) => {
    return text ? text.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : text;
}


export { debounce, indianCurrency, usernameSalutation, currencyFormatter, timeFormatter, formatLocationText }

export const handleIsDateInBetween = (day1, day2) => {
    return (
        new Date() > new Date(day1)  &&
        new Date() < new Date(day2)
    )
}

const keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='

const triplet = (e1, e2, e3) =>
    keyStr.charAt(e1 >> 2) +
    keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
    keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
    keyStr.charAt(e3 & 63)

export const rgbDataURL = (r, g, b) => {
    return `data:image/gif;base64,R0lGODlhAQABAPAA${
    triplet(0, r, g) + triplet(b, 255, 255)
    }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`
}